import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import Header from './component/Header';
import Footer from './component/Footer';
import Banner from './component/Banner';
import About from './component/About';
import Catagory from './component/Catagory';
import Videos from './component/Videos';
import Contact from './component/Contact';
import Privacy from './component/Privacy';
import Gallery from './component/Gallery';
import Conditions from './component/Terms&Conditions';
import Disclaimer from './component/Disclaimer';

function App() {
  return (
    <Router>
      <div id="root">
        <Header />
        {/* <div className="main-content"> */}
          <Routes>
            <Route path="/" element={<><Banner /><Catagory /></>} /> 
            <Route path="/about" element={<About />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/Video" element={<Videos />} />
            <Route path="/Privacy" element={<Privacy />} />
            <Route path="/Condition" element={<Conditions />} />
            <Route path='/Disclaimer' element={<Disclaimer/>} />
            <Route path="/Gallery" element={<Gallery />}></Route>
          </Routes>
        {/* </div> */}
        <Footer />
      </div>
    </Router>
  );
}

export default App;