import React from 'react';
import './Footer.css';
import { useNavigate } from 'react-router-dom';
const Footer = () => {
    const navigate = useNavigate(); 
    const handlePrivacy = () => {
        navigate('/privacy');
    }
    const handleConditions = () => {
        navigate('Condition');
    }
    const handleDisclaimer = () => {
        navigate('Disclaimer');
    }
    return (
        <>
            <footer className="footer">
                <div className="footer-content">
                    <div className="footer-column">
                        <div className="footer-description">
                            <p>
                                The website is dedicated to showcasing the life, teachings, and events of Acharya Rajendraprasad Shastriji, 
                                who has touched the lives of millions with his spiritual wisdom and divine presence. 
                                {/* <a href="/read-more"> Read More</a> */}
                            </p>
                        </div>
                    </div>

                    <div className="footer-column">
                        <h3>For Daily Updates</h3>
                        <div className="footer-subscribe">
                                <div className="subscribe-row">
                                    <input type="email" placeholder="Email" />
                                    <button>SUBSCRIBE NOW</button>
                                </div>
                        </div>
                    </div>

                    <div className="footer-column">
                    <div className="footer-topics">
                        <h3>Social Media</h3>
                        <div className="footer-socials">
                            <a href="https://x.com/RPShastriji" target="_blank" rel="noopener noreferrer" className='twitter'> 
                                <i className="fab fa-twitter"></i> 
                            </a>
                            <a href="https://www.youtube.com/channel/UCGkJjn3nxW0aI1sJ8idTpnQ" target="_blank" rel="noopener noreferrer">
                                <i className="fab fa-youtube"></i> 
                            </a>
                            <a href="https://www.instagram.com/rajendraprasadshastriji/" target="_blank" rel="noopener noreferrer">
                                <i className="fab fa-instagram"></i> 
                            </a>
                            <a href="https://www.linkedin.com/company/105158470/admin/dashboard/" target="_blank" rel="noopener noreferrer">
                                <i className="fab fa-linkedin"></i> 
                            </a>
                            <a href="https://www.facebook.com/profile.php?id=61565614320568" target="_blank" rel="noopener noreferrer">
                                <i className="fab fa-facebook"></i> 
                            </a>
                        </div>
                    </div>

                    </div>
                </div>
            </footer>
            <div className='footer-two'>
                    <p className='copyright-text'>
                        Copyright ©2024 Rajendraprasad Shastriji.
                    </p>
                    <div className='footer-nav'>
                        <ul>
                            <li><a href='' onClick={handlePrivacy}>Privacy Policy</a></li>
                            <li><a href='' onClick={handleConditions}>Terms & Condition</a></li>
                            <li><a href='' onClick={handleDisclaimer}>Disclaimer</a></li>
                        </ul>
                    </div>
            </div>
        </>
       
    );
   
}

export default Footer;
