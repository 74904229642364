import React from 'react'
import './Gallery.css'
import photo3 from './assests/photo3.jpeg';
import photo4 from './assests/photo4.jpeg';
import photo5 from './assests/photo5.jpeg';
import photo6 from './assests/photo6.jpeg';
import photo7 from './assests/photo7.jpeg';
import photo8 from './assests/photo8.jpeg';
import photo9 from './assests/photo9.jpeg';
import photo10 from './assests/photo10.jpeg';
import photo11 from './assests/photo11.jpeg';
import photo12 from './assests/photo12.jpeg';
import photo21 from './assests/photo21.jpeg';
import photo16 from './assests/photo16.jpeg';
import photo17 from './assests/photo17.jpeg';
import photo18 from './assests/photo18.jpeg';
import photo19 from './assests/photo19.jpeg';

export default function Gallery() {
  return (
    <div className='gallery'>
        <h1 className='gallery-text'>GALLERY</h1>
        <div className='photo-gallery'>
          <div className='column'>
            <div className='photo'>
              <img src= {photo3} alt='category'></img>
            </div>
            <div className='photo'>
              <img src= {photo4} alt='category'></img>
            </div>
            <div className='photo'>
              <img src= {photo5} alt='category'></img>
            </div>
            <div className='photo'>
              <img src= {photo12} alt='category'></img>
            </div>
          </div>
          <div className='column'>
            <div className='photo'>
              <img src= {photo6} alt='category'></img>
            </div>
            <div className='photo'>
              <img src= {photo7} alt='category'></img>
            </div>
            <div className='photo'>
              <img src= {photo8} alt='category'></img>
            </div>
            <div className='photo'>
              <img src= {photo21} alt='category'></img>
            </div>
            <div className='photo'>
              <img src= {photo16} alt='category'></img>
            </div>
            <div className='photo'>
              <img src= {photo17} alt='category'></img>
            </div>
          </div>
          <div className='column'>
            <div className='photo'>
              <img src= {photo9} alt='category'></img>
            </div>
            <div className='photo'>
              <img src= {photo10} alt='category'></img>
            </div>
            <div className='photo'>
              <img src= {photo11} alt='category'></img>
            </div>
            <div className='photo'>
              <img src= {photo18} alt='category'></img>
            </div>
            <div className='photo'>
              <img src= {photo19} alt='category'></img>
            </div>
          </div>
          
        </div>
    </div>
  )
}

