import React from 'react';
import '../App.css';
import { useNavigate } from 'react-router-dom';
import photo15 from '../component/assests/photo15.jpeg'; 
import { FaBars, FaTimes } from 'react-icons/fa'; 

export default function Header() {
    const navigate = useNavigate(); 
    const [showDropdown, setShowDropdown] = React.useState(false);
    const [isMobileMenuOpen, setMobileMenuOpen] = React.useState(false); 

    const toggleDropdown = () => {
        setShowDropdown(!showDropdown);
    };
  
    const toggleMobileMenu = () => {
        setMobileMenuOpen(!isMobileMenuOpen); 
    };

    const handleAboutClick = () => {
        navigate('/about');
    };

    const handleGalleryClick = () => {
        navigate('/gallery');
    };

    const handleContactClick = () => {
        navigate('/contact');
    };

    return (
        <>
            <nav className='nav-bar'>
                <div className='nav-logo'>
                    <img src={photo15} alt='nav' className='logo' />
                </div>
                <div className='mobile-menu-icon' onClick={toggleMobileMenu}>
                    {isMobileMenuOpen ? <FaTimes /> : <FaBars />} 
                </div>
                <ul className={isMobileMenuOpen ? 'active' : ''}> 
                    <li><a href='/'>Home</a></li>
                    <li><a href='/about' onClick={handleAboutClick}>About</a></li>
                    <li>
                        <a href="/video" onClick={toggleDropdown} className={showDropdown ? 'show-dropdown' : ''}>
                            Videos 
                        </a>
                    </li>
                    <li><a href='/gallery' onClick={handleGalleryClick}>Gallery</a></li>
                    <li><a href='/contact' onClick={handleContactClick}>Contact</a></li>
                </ul>
            </nav>
        </>
    );
}