import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './Video.css';

const Videos = () => {
  const [shorts, setShorts] = useState([]);
  const [longVideos, setLongVideos] = useState([]);
  const API_KEY = 'AIzaSyCSveJntPkzK7oRUPAGLtCLqOb_2HwW6pY'; 
  const CHANNEL_ID = 'UCGkJjn3nxW0aI1sJ8idTpnQ'; 

  useEffect(() => {
    const fetchVideos = async () => {
      try {
        // Fetch YouTube Shorts
        const shortsResponse = await axios.get(
          `https://www.googleapis.com/youtube/v3/search?key=${API_KEY}&channelId=${CHANNEL_ID}&part=snippet,id&type=video&order=date&maxResults=5000&q=%23shorts`
        );

        console.log('Shorts Response:', shortsResponse.data);

        // Fetch long videos
        const longResponse = await axios.get(
          `https://www.googleapis.com/youtube/v3/search?key=${API_KEY}&channelId=${CHANNEL_ID}&part=snippet,id&type=video&order=date&maxResults=5000&videoDuration=long`
        );

        // Set the results into separate state variables
        setShorts(shortsResponse.data.items);
        setLongVideos(longResponse.data.items);
      } catch (error) {
        console.error('Error fetching videos:', error);
      }
    };

    fetchVideos();
  }, []);

  return (
    <div>
      <h2>Latest Videos from Shastriji</h2>
      
      <section>
        <h3>YouTube Shorts</h3>
        <ul className='youtube-video'>
          {shorts.map((video) => (
            <li key={video.id.videoId}>
              <h4>{video.snippet.title}</h4>
              <iframe
                width="560"
                height="315"
                src={`https://www.youtube.com/embed/${video.id.videoId}`}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                title={video.snippet.title}
              ></iframe>
            </li>
          ))}
        </ul>
      </section>

      <section>
        <h3>Long Videos</h3>
        <ul className='youtube-video'>
          {longVideos.map((video) => (
            <li key={video.id.videoId}>
              <h4>{video.snippet.title}</h4>
              <iframe
                width="560"
                height="315"
                src={`https://www.youtube.com/embed/${video.id.videoId}`}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                title={video.snippet.title}
              ></iframe>
            </li>
          ))}
        </ul>
      </section>
    </div>
  );
};

export default Videos;







































// import React, { useEffect, useState } from 'react';
// import axios from 'axios';
// import './Video.css';

// const Videos = () => {
//   const [videos, setVideos] = useState([]);
//   const API_KEY = 'AIzaSyBTRpr4gGBn3p5KamBlSDZuwQHx_A5GbC4'; 
//   const CHANNEL_ID = 'UCGkJjn3nxW0aI1sJ8idTpnQ'; 

//   useEffect(() => {
//     const fetchVideos = async () => {
//       try {
//         const response = await axios.get(
//           `https://www.googleapis.com/youtube/v3/search?key=${API_KEY}&channelId=${CHANNEL_ID}&part=snippet,id&type=video&order=date&maxResults=5000`
//         );
//         console.log(response.data);
          
//         setVideos(response.data.items);
        
//       } catch (error) {
//         console.error('Error fetching videos:', error);
//       }
//     };

//     fetchVideos();
//   }, []);

//   return (
//     <div>
//       <h2>Latest Videos from Shastriji</h2>
//       <ul className='youtube-video'>
//         {videos.map((video) => (
//           <li key={video.id.videoId}>
//             <h3>{video.snippet.title}</h3>
//             <iframe
//               width="560"
//               height="315"
//               src={`https://www.youtube.com/embed/${video.id.videoId}`}
//               frameBorder="0"
//               allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
//               allowFullScreen
//               title={video.snippet.title}
//             ></iframe>
//           </li>
//         ))}
//       </ul>
//     </div>
//   );
// };

// export default Videos;

  

